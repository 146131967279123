<div class="container-fluid">
    <div class="row">
        <!-- Sidebar Navigation -->
        <nav class="col-md-3 col-lg-2 d-md-block bg-light sidebar py-3">
            <h5 class="px-3">API Docs</h5>
            <ul class="nav flex-column">
                <li class="nav-item">
                    <a class="nav-link active" [routerLink]="[]" [fragment]="'introduction'">Introduction</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="[]" [fragment]="'authentication'">Authentication</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="[]" [fragment]="'create-enquiry'">Create Enquiry</a>
                </li>
            </ul>
        </nav>

        <!-- Main Content -->
        <main class="col-md-9 col-lg-10 px-md-5 py-4">
            <!-- Introduction -->
            <section id="introduction">
                <h2>Introduction</h2>
                <p>Welcome to the API documentation for MatLead service. Follow the steps to integrate smoothly.</p>
            </section>

            <hr>

            <!-- Authentication -->
            <section id="authentication">
                <h3>Authentication</h3>
                <p>To authenticate, pass the API key in the header:</p>
                <pre class="bg-dark text-white p-3 rounded"><code>X-API-TOKEN: YOUR_API_TOKEN_HERE</code></pre>
            </section>

            <hr>

            <!-- Create Order API -->
            <section id="create-enquiry">
                <h3>Create Enquiry</h3>
                <p>Endpoint to create an enquiry.</p>
                
                <div class="card">
                    <div class="card-header bg-primary text-white small">API Endpoint</div>
                    <div class="card-body">
                        <pre class="bg-light p-3 border rounded"><code>POST https://api.matlead.com/v1/enquiry</code></pre>
                    </div>
                </div>

                <h5 class="mt-4">Request Example</h5>
                <pre class="bg-light p-3 rounded border"><code>&#123;
    "name": "Punit Rajkumar",
    "mobile": "9999999999",
    "email": "test&#64;example.com",
    "createdBy": "Aqua Business",
    "createdByName": "Landing Form",
    "mobileVerified":1,
    "fields": [
            &#123;
                "key": "Requirement",
                "value": "Aqua Filter"
            &#125;,
            &#123;
                "key": "Capacity",
                "valueList": ["10 Ltr", "15 Ltr", "25 Ltr"]
            &#125;
        ]
&#125;</code></pre>

                <div class="container mt-5">
                    <div class="card">
                        <div class="card-header">
                            <h5>Field Information</h5>
                        </div>
                        <div class="card-body">
                            <div class="list-group">
                                <!-- Name Field (Mandatory) -->
                                <div class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>Name</span>
                                        <span class="badge bg-danger">Mandatory</span>
                                    </div>
                                    <small class="text-muted">Max Length: 45 | Alphanumeric</small>
                                </div>

                                <!-- Mobile Field (Mandatory) -->
                                <div class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>Mobile</span>
                                        <span class="badge bg-danger">Mandatory</span>
                                    </div>
                                    <small class="text-muted">Max Length: 15 | Numeric</small>
                                </div>

                                <!-- Email Field (Optional) -->
                                <div class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>Email</span>
                                        <span class="badge bg-secondary">Optional</span>
                                    </div>
                                    <small class="text-muted">Max Length: 255 | Format: example&#64;domain.com</small>
                                </div>

                                <!-- Created By Field (Optional) -->
                                <div class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>Created By</span>
                                        <span class="badge bg-secondary">Optional</span>
                                    </div>
                                    <small class="text-muted">Max Length: 16 Characters</small>
                                </div>

                                <!-- Created By Name Field (Optional) -->
                                <div class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>Created By Name</span>
                                        <span class="badge bg-secondary">Optional</span>
                                    </div>
                                    <small class="text-muted">Max Length: 16 Characters</small>
                                </div>

                                <!-- Mobile Verified Field (Optional) -->
                                <div class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>Mobile Verified</span>
                                        <span class="badge bg-secondary">Optional</span>
                                    </div>
                                    <small class="text-muted">Value: 0 (Not Verified), 1 (Verified)</small>
                                </div>

                                <!-- Fields Field (Optional) -->
                                <div class="list-group-item">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>Fields</span>
                                        <span class="badge bg-secondary">Optional</span>
                                    </div>
                                    <small class="text-muted">Max 5 Fields Allowed</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h5 class="mt-4">Response Example: Success</h5>
                <h5 class="mt-4">Response Code: 200</h5>
                <pre class="bg-light p-3 rounded border"><code>&#123;
    "success": true,
    "id": "enquiry_122",
    "status": "created"
&#125;</code></pre>

                <h5 class="mt-4">Response Example: Failure</h5>
                <h5 class="mt-4">Response Code: 200</h5>
                <pre class="bg-light p-3 rounded border"><code>&#123;
    "success": false,
    "status": "Failed",
    "error": &#123; "message": "Name not passed in Request"&#125;
&#125;</code></pre>
            </section>
        </main>
    </div>
</div>
