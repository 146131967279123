export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyDzVD1_UuAoz_IHNH82d22PYFz95yDcYZU",
        authDomain: "matools-91637.firebaseapp.com",
        projectId: "matools-91637",
        storageBucket: "matools-91637.firebasestorage.app",
        messagingSenderId: "198874268040",
        appId: "1:198874268040:web:0ef7d501b5a4a142ecba3d",
        measurementId: "G-ZBLK5R8JF9"

        // apiKey: "",
        // authDomain: "",
        // projectId: "",
        // storageBucket: "",
        // messagingSenderId: "",
        // appId: "",
        // measurementId: ""
    }
};