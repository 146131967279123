<div class="container mt-5">
    <div class="card shadow-lg border-danger">
        <div class="card-header bg-danger text-white text-center">
            <h3>Delete Your Account</h3>
        </div>
        <div class="card-body">
            <p>
                You can delete your account permanently from the MatLead app.  
                To proceed, Open MatLead App, navigate to <strong>MatLead → Settings → Account → Delete Account</strong>.  
            </p>
            <br>
            <br>
            <br>
            <h5>How to Delete Your Account:</h5>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">Login to <strong>MatLead</strong></li>
                <li class="list-group-item">Click on <strong><i class="bi bi-three-dots-vertical"></i></strong> on right top</li>
                <li class="list-group-item">Go to <strong>Settings</strong> → <strong>Account</strong></li>
                <li class="list-group-item">Select <strong>Delete Account</strong></li>
                <li class="list-group-item">Accept the <strong>terms and conditions</strong></li>
                <li class="list-group-item">Confirm to delete your account permanently</li>
            </ul>
            <br>
            <br>
            <p>
                <strong>Important:</strong>  
                - Once deleted, all your data will be removed permanently.  
                - You will not be able to reuse the same number for a new account.  
            </p>

            <p>
                <strong>Query:</strong>  
                - For any doubts please feel free to message us on WhatsApp on +919611340208 
            </p>
            
            
        </div>
    </div>
</div>
