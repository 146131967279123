<div class="container">
    <h1>Terms and Conditions of Use</h1>
    <p><strong>Effective Date:</strong> 8-2-2025</p>

    <p>Welcome to MatLead, a product of <strong>Mathrusoft Technologies Private Limited</strong>. By accessing or using the MatLead app, web application, or APIs ("Services"), you agree to comply with the following Terms and Conditions. Please read them carefully.</p>

    <hr>

    <h2>1. Acceptance of Terms</h2>
    <p>By using our Services, you agree to be bound by these Terms and Conditions, as well as our Privacy Policy. If you do not agree with any part of these terms, please discontinue use immediately.</p>

    <h2>2. Eligibility</h2>
    <p>You must be at least 18 years of age to use the Services. By using the Services, you confirm that you meet this requirement.</p>

    <h2>3. User Account & Responsibilities</h2>
    <ul>
        <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
        <li>Any activity under your account is your sole responsibility.</li>
        <li>You agree not to use the Services for illegal or unauthorized purposes.</li>
    </ul>

    <h2>4. Use of Services</h2>
    <ul>
        <li>MatLead provides tools for managing business transactions and communications.</li>
        <li>You agree not to misuse the Services or attempt unauthorized access.</li>
    </ul>

    <h2>5. Payment & Subscription</h2>
    <ul>
        <li>Certain features may require payment or a subscription.</li>
        <li>Payments are processed through third-party providers, and you agree to their respective terms.</li>
    </ul>

    <h2>6. <strong>Refund & Cancellation Policy</strong></h2>
    <p><strong>No return or refund of service or product.</strong></p>
    <p><strong>No exchange.</strong></p>

    <h2>7. Intellectual Property</h2>
    <ul>
        <li>All content, trademarks, logos, and software associated with MatLead are owned by Mathrusoft Technologies Private Limited.</li>
        <li>Unauthorized copying, distribution, or modification is strictly prohibited.</li>
    </ul>

    <h2>8. Termination</h2>
    <p>We reserve the right to suspend or terminate your access to the Services at any time for any violation of these Terms.</p>

    <h2>9. Limitation of Liability</h2>
    <ul>
        <li>Mathrusoft Technologies Private Limited is not liable for any indirect, incidental, or consequential damages arising from the use of the Services.</li>
        <li>We do not guarantee uninterrupted or error-free service.</li>
    </ul>

    <h2>10. Governing Law</h2>
    <p>These Terms are governed by the laws of India. Any disputes will be subject to the jurisdiction of courts in [Insert Jurisdiction].</p>

    <h2>11. Contact Information</h2>
    <p>For any questions regarding these Terms, please contact us at:</p>
    <p><strong>Mathrusoft Technologies Private Limited</strong></p>
    <p>Email: <a href="mailto:hello@mathrusoft.com">hello&#64;mathrusoft.com</a></p>
    <p>Address: 726 Kuntoji Muddebihal Taluk Bijapur District</p>

    <hr>

    <p>By continuing to use MatLead, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>
</div>
