<h2>1. Introduction</h2>
<p>Welcome to MatLead, a B2B product of Mathrusoft Technologies Private Limited ("we," "our," or "us"). We are committed to protecting the privacy of businesses and their representatives. This Privacy Policy explains how we collect, use, disclose, and safeguard business and personal data when you use our services.</p>

<p>This privacy policy ("Policy") explains our policy regarding the collection, use, disclosure, and transfer of your information by Mathrusoft Technologies Pvt. Ltd. or any of its affiliate(s) ("we" or "us" or "our"), which operates the website [Insert MatLead Site URL] ("Site"), “MatLead App” (the "App") available on Google Play Store, iOS, and other similar platforms and other services including but not limited to recording credit/payment, communicating dues/balances via the site and application, any mobile or internet-connected device, or otherwise (collectively the "MatLead Service"). This Policy forms part and parcel of the Terms of Use for the MatLead Services. Capitalized terms which have been used here but are undefined shall have the same meaning as attributed to them in the Terms of Use. Users (as defined hereinafter) are requested to read this Policy in conjunction with the Terms of Use.</p>

<p>The App/Site (i) provides an online digital ledger book/record book wherein the Users can record their ledger entries with their respective customers and (ii) also allows Users to generate a payment link which can be sent to their respective customers, and the customers could use the payment link and make the payment of the amount to the Users in settlement of their obligations towards the Users (collectively, "Services"). Please note that "Services" would include any other future services we may provide or propose to provide.</p>

<p>As we update, improve, and expand the MatLead Service, this Policy may change, so please refer back to it periodically. By accessing the Site, App, or otherwise using the MatLead Services, you consent to the collection, storage, and use of the personal information you provide (including any changes thereto as provided by you) for any of the services that we offer.</p>

<p>We respect the privacy of the users of the MatLead Services ("Users" or "you") and are committed to reasonably protecting it in all respects. The information about the User as collected by us is: (a) information supplied by Users and (b) information automatically tracked while using a mobile device having MatLead Services enabled (collectively referred to as "Information"). By using MatLead Services, you agree to this Policy. If you do not agree with any term in this Policy, please do not use/avail of the MatLead Services.</p>
<h2>2. Information We Collect</h2>
<p>We may collect the following types of information:</p>
<ul>
    <li><strong>Business Information:</strong> Company name, business address, industry, and contact details.</li>
    <li><strong>Personal Information:</strong> Name, email address, phone number, job title, and other details provided during account registration or communication.</li>
    <li><strong>Transactional Data:</strong> Billing details, payment history, and business transactions conducted through MatLead.</li>
    <li><strong>Usage Data:</strong> Information about interactions with MatLead, including log data, IP addresses, and browser details.</li>
    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to enhance user experience, track activities, and analyze trends.</li>
    <li><strong>Communication Data:</strong> Users can send text messages, including email subject lines, sender details, recipients, and email content.</li>
    <li><strong>App Interactions:</strong> Information about user interactions with the app, such as pages visited and sections accessed.</li>
    <li><strong>Device Information:</strong> Details about installed apps, device identifiers, browser information, and app-related IDs.</li>
    <li><strong>Crash & Diagnostics:</strong> Data about app crashes and performance for technical analysis.</li>
    <li><strong>User-Generated Content (UGC):</strong> Any content submitted by users, such as comments or inquiries via phone or email.</li>
    <li><strong>Tracking & Analytics:</strong> We use tools like Google Analytics, Google Webmaster, cookies, and web beacons to analyze platform usage.</li>
    <li><strong>Usage of Information:</strong> We use collected data to respond to user inquiries, confirm transactions, enhance user experience, analyze trends, ensure security, and conduct marketing activities.</li>
    
</ul>
<p>The information provided by users enables us to offer and enhance MatLead App services, ensuring a seamless and user-friendly experience. In certain cases, we may require your contact address to provide specific services or utilities.</p>
<p>Upon downloading the app, users are requested to grant access to their contact list (stored on their mobile device) to facilitate sharing payment and credit updates with their customers, whether they are MatLead users or not. This contact list is securely uploaded to <a href="https://api.matlead.com">https://api.matlead.com</a> to improve and enhance the user experience.</p>
<p>As part of our ongoing efforts to refine our services, we may occasionally collect additional information beyond what is explicitly mentioned above through the app or website. Rest assured, we do not share this data with any third party except as outlined in our policy.</p>

<p><strong>Customer Information:</strong> We store the contact details of your customers and maintain a log of all credit and payment-related communications with them.</p>

<p><strong>Demographic and Related Information:</strong> To provide more personalized communications and promotions, we may reference additional demographic and related data sources. We also utilize tools like Google Analytics to track user behavior on the MatLead App and website.</p>

<p><strong>Log File Information:</strong> Our servers automatically collect limited information about your device’s internet connection, such as your IP address, when you visit our site or use the app. Additionally, we receive and log data from your app or browser, including but not limited to your IP address, device name, operating system, and log details such as location, device serial number, unique identifiers (e.g., UDID for iOS, Android ID or ADID for Android), browser type and version, CPU speed, and connection speed.</p>

<p><strong>Cookies:</strong> To enhance user experience, we may use cookies or similar electronic tools to assign a unique, random User ID for tracking individual interests. By continuing to use the MatLead App or website, you agree to the use of cookies and similar technologies as outlined in this policy. If you prefer to disable cookies, you can adjust your browser or third-party cookie settings. Additionally, our partners may set their own cookies, a process we do not control.</p>


<p><strong>Links to Third-Party Sites and Ad-Servers:</strong></p>

<p>The MatLead App and website may contain links to third-party websites, including advertisers, blogs, content sponsorships, vendor services, and social networks. These external sites operate under their own privacy policies, which are beyond our control.</p>

<p>Once you leave our platform (you can verify this by checking the URL in your browser’s address bar), any information you provide is subject to the privacy policy of the respective third-party site. MatLead does not make any representation or warranty regarding how your data is stored or used on these external servers, and their policies may differ from ours.</p>

<p>If you are unable to locate the privacy policy of a third-party site via a link on their homepage, we recommend contacting them directly for further details.</p>

<h2>3. How We Use Your Information</h2>
<p>We use collected information for purposes such as:</p>
<ul>
    <li>Providing and maintaining our B2B services.</li>
    <li>Enhancing user experience and improving MatLead’s features.</li>
    <li>Facilitating business transactions and communications.</li>
    <li>Responding to inquiries and customer support requests.</li>
    <li>Ensuring security, preventing fraud, and compliance with legal obligations.</li>
    <li>Conducting research and business analytics.</li>
</ul>

<h2>4. Sharing Your Information</h2>
<p>We do not sell or rent business or personal information. However, we may share data with:</p>
<ul>
    <li>Service providers assisting in delivering our services, including payment processing and data hosting.</li>
    <li>Legal authorities when required by law or in case of disputes.</li>
    <li>Business partners in case of mergers, acquisitions, or corporate restructuring.</li>
    <li>Other authorized business entities upon user consent.</li>
</ul>

<h2>5. Data Security</h2>
<p>We implement industry-standard security measures to protect data from unauthorized access, modification, or loss. However, no method of transmission over the Internet is 100% secure.</p>

<h2>6. Your Rights and Choices</h2>
<p>Depending on your location and applicable laws, you may have the right to:</p>
<ul>
    <li>Access, update, or delete your business and personal data.</li>
    <li>Opt out of certain data processing activities.</li>
    <li>Withdraw consent where applicable.</li>
</ul>

<h2>7. Contact Us</h2>
<p>For questions regarding this Privacy Policy, please contact us at:</p>
<p><strong>Mathrusoft Technologies Private Limited</strong><br>
Email: <a href="mailto:hello@mathrusoft.com">hello&#64;mathrusoft.com</a></p>

<p><strong>Disclaimer:</strong></p>  

<p>If you provide any personal information that was not explicitly requested during the registration process (whether mandatory or optional), MatLead will not be responsible for any security breach or unauthorized disclosure related to such information.</p>  

<p>For any questions regarding this policy or concerns about the protection of your personal information, please reach out to our Data Protection Officer or Grievance Officer.</p>  
