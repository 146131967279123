import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterLink, RouterOutlet } from "@angular/router";
import { environment } from '../environments/environment';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { ActionBarComponent } from "./header/action-bar/action-bar.component";
import { DeleteComponent } from "./delete/delete.component";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home/home.component";

@NgModule({
    declarations: [AppComponent, FooterComponent, DeleteComponent, HomeComponent],
    bootstrap: [AppComponent],
    imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    ActionBarComponent,
    RouterOutlet,
    RouterLink
]
    
})
export class AppModule {}