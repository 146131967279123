<br>
<br>
<br>
<div class="col-md-12">
           
    <div class="d-flex justify-content-center mb-5">
        <a href="https://play.google.com/store/apps/details?id=com.mathrusoft.matlead&utm_source=website&utm_medium=click&utm_campaign=website" target="_blank" class="btn btn-success btn-sm d-flex align-items-center">
          <img src="playstore.svg" alt="Play Store" style="width: 20px; height: 20px; margin-right: 8px;">
          <strong>Download</strong>
        </a>
      </div>
     <p class="text-xxl-left mt-5">
     Owned by Mathrusoft Technologies Private Limited
     </p>
     <div>Registered Address:</div>
     <Address>
        <div>#726 Kuntoji Muddebihal Taluk Vijaypur District 586212</div>
     </Address>
     <br>
     <div>Office Address:</div>
     <Address>
        <div>#3A Vrishank Nilaya 14th Main Billekahalli Bengaluru 560076</div>
     </Address>

     <div>Mathrusoft Technologies Private Limited <i class="bi bi-c-circle"></i> {{date | date : 'yyyy'}} </div>
     <br>
     <br>
     <p>Need Help? Contact us:</p>
     <div class="mt-4">
         <a href="https://wa.me/+919611340208" class="text-success text-decoration-none" target="_blank">
             <i class="fab fa-whatsapp"></i> WhatsApp: +919611340208
         </a>
         <br>
         <a href="mailto:support@mathrusoft.com" class="text-primary text-decoration-none">
             <i class="fas fa-envelope"></i> Email: support&#64;mathrusoft.com 
         </a>
     </div>
</div>
<br>
<br>
<br>
<br>
<br>
<br>