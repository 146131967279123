import { Routes } from '@angular/router';
import { DeleteComponent } from './delete/delete.component';
import { PrivacyComponent } from './terms/privacy/privacy.component';
import { TermsOfUseComponent } from './terms/terms-of-use/terms-of-use.component';
import { HomeComponent } from './home/home.component';
import { ApiIntegrationComponent } from './api-integration/api-integration.component';

export const routes: Routes = [{
        path:"", 
        component:HomeComponent
    },
    {
        path:"terms-of-use", 
        component:TermsOfUseComponent
    },
    {
        path:"privacy-policy", 
        component:PrivacyComponent
    },
    {
        path:"delete-account", 
        component: DeleteComponent
    },
    {
        path:"api-integration", 
        component: ApiIntegrationComponent
    }
];
