import { Component, AfterViewInit } from '@angular/core';
import { text } from 'body-parser';
declare var bootstrap: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements AfterViewInit {
  sections = [
    { image: "screens/buyer_search.png", title: "Search", text: "Let your business appear in buyer search results. Sign up today!" },
    { image: "screens/buyer_chat.png", title: "Chat", text: "Buyers can directly connect with businesses via chat." },
    { image: "screens/seller_chat.png", title: "Chat", text: "Engage with potential buyers and convert them into customers." },
    { image: "screens/settings.png", title: "Settings", text: "Customize the app to suit your business needs." },
    { image: "screens/form_list.png", title: "Enquiry Form", text: "Choose an enquiry form that fits your requirements or request a custom form." },
    { image: "screens/dashboard.png", title: "Dashboard", text: "A simple and intuitive dashboard for quick access to insights." },
  
    { image: "screens/2_login_details.png", title: "Login Details", text: "We collect customer details for KYC (Know Your Customer) verification." },
    { image: "screens/3_qr_code_list.png", title: "QR Code List", text: "After signing up, you will receive your unique QR codes." },
    { image: "screens/4_download_qrcode.png", title: "Download QR Code", text: "Download, print, and display your QR code for easy customer access." },
    { image: "screens/5_qr_code_details.png", title: "How QR Codes Work", text: "Understand how QR codes facilitate seamless transactions." },
    { image: "screens/6_open_enquiry.png", title: "Open Enquiry", text: "Allow customers to send enquiries directly through the QR code." },
    { image: "screens/7_positive_close.png", title: "Mark Lead as Positive", text: "Follow up with personalized messages to nurture leads and convert them into customers." }
  ];
  

  

  ngAfterViewInit() {
    const carouselElement = document.getElementById('carouselExample');

    if (carouselElement) {
      const carousel = new bootstrap.Carousel(carouselElement, {
        interval: 3000, // Auto-scroll every 3s
        ride: 'carousel'
      });

      // Enable swipe gestures
      let touchStartX = 0;
      let touchEndX = 0;

      carouselElement.addEventListener('touchstart', (event: TouchEvent) => {
        touchStartX = event.touches[0].clientX;
      });

      carouselElement.addEventListener('touchend', (event: TouchEvent) => {
        touchEndX = event.changedTouches[0].clientX;
        this.handleSwipe(carouselElement, touchStartX, touchEndX);
      });
    }
  }

  handleSwipe(carouselElement: HTMLElement, touchStartX: number, touchEndX: number) {
    const swipeThreshold = 50; // Minimum swipe distance

    if (touchStartX - touchEndX > swipeThreshold) {
      (carouselElement.querySelector('.carousel-control-next') as HTMLElement)?.click(); // Swipe left → Next slide
    } else if (touchEndX - touchStartX > swipeThreshold) {
      (carouselElement.querySelector('.carousel-control-prev') as HTMLElement)?.click(); // Swipe right → Previous slide
    }
  }
}


