import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  constructor(router:Router){

  }
  title = 'MatLead - Connect Customer and Business : Bridging the gap between customers and businesses for effortless communication and services.';
}
