
<div class="mt-1"></div>
<div class="card ">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <!-- Action Button -->
      <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidePanel" aria-controls="sidePanel">
        <span class="d-none d-md-inline">Side Menu </span> <!-- Visible on medium and larger screens -->
        <i class="bi bi-list"></i> <!-- Icon visible on all screen sizes -->
      </button>

      <!-- Brand -->
      <a class="navbar-brand" href="#">
        <span class="d-lg-inline">MatLead </span> <!-- Visible on large screens -->
        <i class="bi bi-gear-fill"></i><!-- Icon visible on all screen sizes -->
      </a>
    </div>
  </nav>
</div>

  <!-- Side Panel (Offcanvas) -->
  <div class="offcanvas offcanvas-start" tabindex="-1" id="sidePanel" aria-labelledby="sidePanelLabel">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="sidePanelLabel">MatLead</h5>
      <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <a href="/privacy-policy" class="list-group-item list-group-item-action">
        <i class="bi bi-house"></i> Home
      </a>
      <a href="/privacy-policy" class="list-group-item list-group-item-action">
        <i class="bi bi-shield-lock"></i> Privacy Policy
      </a>
      <a href="/terms-of-use" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-exclamation-diamond"></i> Terms of Use
      </a>
      <a href="/api-integration" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-plug"></i> API Integration
      </a>
      <a href="/delete-account" routerLinkActive="selected"  class="list-group-item list-group-item-action">
        <i class="bi bi-trash3"></i> Delete Account
      </a>

      <button class="mt-4 btn btn-secondary" data-bs-dismiss="offcanvas">Close</button>
    </div>
  </div>
