<div class="mt-5 d-flex justify-content-center">
  <a href="https://play.google.com/store/apps/details?id=com.mathrusoft.matlead&utm_source=website&utm_medium=click&utm_campaign=website" target="_blank" class="btn btn-success btn-sm d-flex align-items-center">
    <img src="playstore.svg" alt="Play Store" style="width: 20px; height: 20px; margin-right: 8px;">
    <strong>Download</strong>
  </a>
</div>

  <div id="carouselExample" class="mt-5 carousel slide" data-bs-ride="carousel">
    <!-- Indicators -->
    <div class="carousel-indicators">
      <button *ngFor="let section of sections; let i = index" type="button" data-bs-target="#carouselExample"
        [attr.data-bs-slide-to]="i" [class.active]="i === 0" aria-current="true" [attr.aria-label]="'Slide ' + (i + 1)">
      </button>
    </div>
  
    <!-- Carousel Inner -->
    <div class="carousel-inner">
      <div *ngFor="let section of sections; let i = index" class="carousel-item" [class.active]="i === 0">
        <!-- Title above -->
        <div class="carousel-title">
          <h5>{{ section.title }}</h5>
        </div>
  
        <!-- Image -->
        <img [src]="section.image" class="d-block w-100" [alt]="section.title">
  
        <!-- Text below -->
        <div class="carousel-text">
          <p>{{ section.text }}</p>
        </div>
      </div>
    </div>
  
    <!-- Controls -->
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  
  
  <div class="container text-center my-3">
    <!-- App Name and Play Store Link -->
    <div class="card p-3 shadow-sm rounded">
      <!-- App Name and Tagline -->
      <h4 class="mb-2" style="font-family: 'Arial', sans-serif; color: #333; font-size: 1.5rem;">
        MatLead
      </h4>
      <p class="mb-3" style="font-size: 1rem; color: #777;">
        MatLead - Connect Customer and Business : Bridging the gap between customers and businesses for effortless communication and services.
      </p>
  
      <!-- Description -->
      <p class="mb-3" style="font-size: 1rem;">
        Get started with MatLead today!
      </p>
  
      <!-- Centered and Small Download Button -->
      <div class="d-flex justify-content-center">
        <a href="https://play.google.com/store/apps/details?id=com.mathrusoft.matlead&utm_source=website&utm_medium=click&utm_campaign=website" target="_blank" class="btn btn-success btn-sm d-flex align-items-center">
          <img src="playstore.svg" alt="Play Store" style="width: 20px; height: 20px; margin-right: 8px;">
          <strong>Download</strong>
        </a>
      </div>
    </div>
  </div>